<template>
    <div class="CustomerList">
        <el-page-header @back="back()" content="客户列表"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <!-- 标题 -->
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <!-- 搜索 -->
                <div class="f-c-b feature">
                    <div class="search_input">
                        <input type="text" placeholder="请输入搜索" v-model.trim="searchValue" @keyup.enter="search"
                            maxlength="10" />
                        <el-button class="search_icon" @click="search" icon="el-icon-search"></el-button>
                    </div>
                    <div class="addBtn f-c-c" @click="addCustomer" v-if="enterpriseStateInfo.userPermission.c_m != 'R'"><i
                            class="el-icon-circle-plus-outline"></i>添加</div>
                </div>
                <div class="customerTotal" v-if="isSearch">搜索到 {{ total }} 个与 “{{ searchVal }}” 相关客户</div>
                <div class="customerTotal" v-else>客户总数：{{ total }} 个</div>
                <!-- 列表 -->
                <div class="list f-w">
                    <div class="item f-c-b" v-for="(item, index) of customerList" :key="index"
                        :style="{ marginRight: (index % 2 == 0) ? '20px' : '0' }">
                        <img v-if="item.customerType == 'B'" src="@/assets/supply/enterpriseIcon.png" alt="">
                        <img v-else src="@/assets/supply/personalIcon.png" alt="">
                        <div class="f-cl textBox">
                            <span class="name">客户名称：{{ item.customerName }}</span>
                            <span class="type">客户类型：{{ item.customerType == 'B' ? '企业' : '个人' }}</span>
                            <div class="checkbtn" @click="showCustomerDetail(item.customerId)">查看</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <Dialog :title="title" width="1280px" :visible="addCustomerDialog" @closeDialog="addCustomerCloseDialog('addCusomerform')">
            <div v-if="title == '新增客户' || title == '修改客户信息'" class="f-cl-c">
                <el-form class="f-w" ref="addCusomerform" :model="addCusomerform" label-width="80px"
                    :rules="EnterPriseRules">
                    <el-form-item style="width: 50%;margin-bottom: 40px;" :label="item.label" :prop="item.prop"
                        v-for="(item, index) of addCusomerformArr" :key="index">
                        <el-radio-group v-model="addCusomerform[item.prop]" v-if="item.prop == 'customerType'"
                            :disabled="radioDisabled" @change="customerTypeChange">
                            <el-radio label="B">公司</el-radio>
                            <el-radio label="C">个人</el-radio>
                        </el-radio-group>
                        <ChooseCity style="width: 90% !important;" :city="addCusomerform.customerCity"
                            @handleChangeCity="handleChangeCity" :disabledChange="disabledInput"
                            v-else-if="item.prop == 'customerCity'" :key="new Date().getTime()">
                        </ChooseCity>
                        <div
                            v-else-if="dialogType != 'showCustomerDetail' && (item.prop == 'customerPhone' || item.prop == 'contactPhone1' || item.prop == 'contactPhone2')">
                            <PhoneInput style="width: 90%;" :modelValue="addCusomerform[item.prop]"
                                :placeholderValue="item.placeholder" @getPhoneNumber="getPhoneNumber"
                                :differentType="item.prop" :LengthLimit="'16'" :maxlength="16"
                                :disableValue="disabledInput">
                            </PhoneInput>
                        </div>
                        <!--  -->
                        <ChineseCharactersInput style="width: 90%;"
                            v-else-if="item.prop == 'contact' || item.prop == 'customerAddress'"
                            :modelValue="addCusomerform[item.prop]" :type="item.prop" :placeholderValue="item.placeholder"
                            :disabledValue="disabledInput" :maxlength="'30'" @getValue="getValue">
                        </ChineseCharactersInput>
                        <el-input style="width: 90%;" v-else v-model.trim="addCusomerform[item.prop]" clearable
                            :placeholder="item.placeholder" :disabled="disabledInput">
                        </el-input>
                    </el-form-item>
                    <div class="btns" style="margin: auto;width: 160px;height: 60px;"
                        v-if="enterpriseStateInfo.userPermission.c_m == 'W'">
                        <el-button v-if="dialogType == 'add'"
                            style="width:100%;height: 100%;font-size: 16px;background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);border-radius: 10px;border: none;"
                            type="success" @click="addEnterpriseCustomer('addCusomerform')">确定新增
                        </el-button>
                        <el-button v-if="dialogType == 'modify'"
                            style="width:100%;height: 100%;font-size: 16px;background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);border-radius: 10px;border: none;"
                            type="success" @click="reviseEnterpriseCustomer('addCusomerform')">确定修改
                        </el-button>
                    </div>
                </el-form>
            </div>

            <el-form v-else class="f-w" ref="addCusomerform" :model="addCusomerform" label-width="80px"
                :rules="EnterPriseRules">
                <el-form-item :label="item.label" :prop="item.prop" v-for="(item, index) of addCusomerformArr" :key="index"
                    style="margin-bottom: 40px;" :style="{ width: item.prop == 'customerType' ? '100%' : '50%' }">
                    <el-radio-group style="width: 100%;" v-model="addCusomerform[item.prop]"
                        v-if="item.prop == 'customerType'" :disabled="radioDisabled" @change="customerTypeChange">
                        <el-radio label="B">公司</el-radio>
                        <el-radio label="C">个人</el-radio>
                    </el-radio-group>
                    <div v-else-if="item.prop == 'customerName' || item.prop == 'customerCityAddress'" class="f-c-c">
                        <el-input class="textarea" type="textarea" autosize style="width: 80% !important;font-size: 18px !important;" v-model.trim="addCusomerform[item.prop]" clearable
                            :placeholder="item.placeholder" :disabled="disabledInput">
                        </el-input>
                        <el-button style="margin-left: 20px;" v-if="dialogType == 'showCustomerDetail'" type="primary"
                            @click="copy(addCusomerform[item.prop])">复制</el-button>
                    </div>
                    <div v-else-if="item.prop == 'customerPhone'" class="copy_item f-c">
                        <!-- <PhoneInput style="width: 80% !important;font-size: 18px !important;" :modelValue="addCusomerform[item.prop]"
                            :placeholderValue="item.placeholder" v-if="item.prop == 'customerPhone'"
                            @getPhoneNumber="getPhoneNumber" :differentType="item.prop" :LengthLimit="'16'" :maxlength="16"
                            :disableValue="disabledInput">
                        </PhoneInput>
                        <ChineseCharactersInput style="width: 80% !important;" v-else
                            :modelValue="addCusomerform[item.prop]" :type="item.prop" :placeholderValue="item.placeholder"
                            :disabledValue="disabledInput" :maxlength="'30'" @getValue="getValue">
                        </ChineseCharactersInput> -->
                        <el-input autosize style="width: 80% !important;" v-model.trim="addCusomerform[item.prop]" clearable :placeholder="item.placeholder" :disabled="disabledInput">
                        </el-input>
                        <el-button style="margin-left: 20px;" v-if="dialogType == 'showCustomerDetail'" type="primary"
                            @click="copy(addCusomerform[item.prop])">复制</el-button>
                    </div>
                    <el-input class="textarea" autosize style="width: 80% !important;" v-else v-model.trim="addCusomerform[item.prop]" clearable
                        :placeholder="item.placeholder" :disabled="disabledInput">
                    </el-input>
                </el-form-item>

                <div class="btns f-c-c" style="width: 100%;" v-if="enterpriseStateInfo.userPermission.c_m == 'W'">
                    <div class="f-c-c">
                        <el-button
                            style="width:160px;height: 60px;font-size: 16px;background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);border-radius: 10px;border: none;"
                            type="success" @click="changeModifyCustomer">修改
                        </el-button>
                        <el-button
                            style="width:160px;height: 60px;font-size: 16px;background: linear-gradient(0deg, #F33939 0%, #EA5555 100%);border-radius: 10px;border: none;"
                            type="danger" @click="removeEnterpriseCustomer">删除
                        </el-button>
                    </div>
                </div>
            </el-form>
        </Dialog>
        <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
            @pagination="fetchData" />
    </div>
</template>

<script>
import { EnterPriseRules } from "@/utils/FormRules"
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin'
import { ChineseCharactersInputMixin } from '@/mixins/ChineseCharactersInputMixin'
export default {
    inject: ['reload'],
    name: 'CustomerList',
    components: { NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue') },
    mixins: [PaginationRetention, ChineseCharactersInputMixin],
    data() {
        return {
            title: '',
            customerId: '',
            customerList: [],
            addCustomerDialog: false,//是否显示新增客户盒子
            addCusomerform: {
                customerType: 'B',//B-企业 C-个人
                customerName: '',
                contact: '',
                customerPhone: '',//企业联系电话
                contactPhone1: '',
                contactPhone2: '',
                customerCity: '',
                customerAddress: ''
            },
            radioDisabled: false,
            disabledInput: false,
            dialogType: 'add',
            enterpriseForm: [//企业类型
                {
                    label: '客户类型',
                    prop: 'customerType',
                },
                {
                    label: '客户名称',
                    prop: 'customerName',
                    placeholder: '请输入客户名称'
                },
                {
                    label: '联系人',
                    prop: 'contact',
                    placeholder: '请输入联系人'
                },
                {
                    label: '联系电话',
                    prop: 'customerPhone',
                    placeholder: '请输入联系电话'

                },
                {
                    label: '手机号码',
                    prop: 'contactPhone1',
                    placeholder: '请输入手机号码'
                },
                {
                    label: '备用号码',
                    prop: 'contactPhone2',
                    placeholder: '请输入备用号码'

                },
                {
                    label: '地区',
                    prop: 'customerCity',
                },
                {
                    label: '详细地址',
                    prop: 'customerAddress',
                    placeholder: '如街道、门牌号、大厦、写字楼等'
                },
            ],
            enterpriseFormDetail: [//企业类型详情
                {
                    label: '客户类型',
                    prop: 'customerType',
                },
                {
                    label: '客户名称',
                    prop: 'customerName',
                    placeholder: '请输入客户名称'
                },
                {
                    label: '联系人',
                    prop: 'contact',
                    placeholder: '请输入联系人'
                },
                {
                    label: '联系电话',
                    prop: 'customerPhone',
                    placeholder: '请输入联系电话'

                },
                {
                    label: '手机号码',
                    prop: 'contactPhone1',
                    placeholder: '请输入手机号码'
                },
                {
                    label: '备用号码',
                    prop: 'contactPhone2',
                    placeholder: '请输入备用号码'

                },
                {
                    label: '地址',
                    prop: 'customerCityAddress',
                    placeholder: '如街道、门牌号、大厦、写字楼等'
                },
            ],
            personalForm: [//个人类型
                {
                    label: '客户类型',
                    prop: 'customerType',
                },
                {
                    label: '客户名称',
                    prop: 'customerName',
                    placeholder: '请输入客户名称'
                },
                {
                    label: '联系电话',
                    prop: 'customerPhone',
                    placeholder: '请输入联系电话'

                },
                {
                    label: '备用号码',
                    prop: 'contactPhone2',
                    placeholder: '请输入备用号码'

                },
                {
                    label: '地区',
                    prop: 'customerCity',
                },
                {
                    label: '详细地址',
                    prop: 'customerAddress',
                    placeholder: '如街道、门牌号、大厦、写字楼等'
                },
            ],
            personalFormDetail: [//个人类型详情
                {
                    label: '客户类型',
                    prop: 'customerType',
                },
                {
                    label: '客户名称',
                    prop: 'customerName',
                    placeholder: '请输入客户名称'
                },
                {
                    label: '联系电话',
                    prop: 'customerPhone',
                    placeholder: '请输入联系电话'

                },
                {
                    label: '备用号码',
                    prop: 'contactPhone2',
                    placeholder: '请输入备用号码'

                },
                {
                    label: '地址',
                    prop: 'customerCityAddress',
                    placeholder: '如街道、门牌号、大厦、写字楼等'
                },
            ],
            addCusomerformArr: [],
            EnterPriseRules,
            customerDetails: {},
            isSearch: false,//是否启动搜素
            enterpriseStateInfo: {},//权限信息
            customerTypeVal: 'B',
            searchValue: '',
            searchVal: '',
            viewVisibleVisible: false, // 查看客户信息弹窗
            editviewVisibleVisible: false, // 修改客户信息弹窗

        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
    },
    methods: {

        back(){
            this.$router.go(-1)
            // console.log(this.$router.go(-1))
            console.log(history)
        },
        refresh() {
            this.reload()
        },
        getValue(data) {
            let that = this;
            if (data.type == 'CustomerList') {
                that.searchValue = data.value
            } else {
                that.addCusomerform[data.type] = data.value
            }
        },
        getPhoneNumber(data) {
            let { value, type } = data;
            switch (type) {
                case 'customerPhone':
                    this.addCusomerform.customerPhone = value
                    break;
                case 'contactPhone1':
                    this.addCusomerform.contactPhone1 = value
                    break;
                case 'contactPhone2':
                    this.addCusomerform.contactPhone2 = value
                    break;
            }
        },
        getDate() {
            this.searchListEnterpriseCustomer(this.paging, this.pagingSize)
        },
        // 企业客户搜索列表接口
        searchListEnterpriseCustomer(page = this.page, size = this.size) {
            let that = this;
            that.$http.searchListEnterpriseCustomer({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                customerName: that.searchValue,
                page,
                size
            }).then(res => {
                that.searchVal = that.searchValue;
                that.customerList = res.data.records
                that.currentPage = res.data.current;//当前页数
                that.total = res.data.total
            })
        },
        // // 搜索列表
        search() {
            // if (!this.searchValue) {
            //     return this.$common.message('请输入内容搜索！', 'warning')
            // }
            this.searchValue ? this.isSearch = true : this.isSearch = false;
            this.customerList = [];
            this.searchListEnterpriseCustomer()
        },
        // 新增客户
        addCustomer() {
            this.title = '新增客户';
            this.dialogType = 'add';
            this.addCusomerform = {
                customerType: 'B',
                customerName: '',
                contact: '',
                customerPhone: '',//企业联系电话
                contactPhone1: '',
                contactPhone2: '',
                customerCity: '',
                customerAddress: ''
            };
            this.radioDisabled = false;  // 禁选
            this.disabledInput = false;  // 禁填
            this.addCusomerformArr = this.enterpriseForm  // 默认添加企业客户
            this.addCustomerDialog = true;  // 打开新增客户弹窗

        },
        // 新增客户关闭
        addCustomerCloseDialog(from) {
            this.addCustomerDialog = false
            this.addCusomerform = {
                customerType: 'B',//B-企业 C-个人
                customerName: '',
                contact: '',
                customerPhone: '',//企业联系电话
                contactPhone1: '',
                contactPhone2: '',
                customerCity: '',
                customerAddress: ''
            }
            this.$refs[from].resetFields();
        },
        // 展示客户信息
        showCustomerDetail(customerId) {
            this.customerId = customerId
            this.enterpriseCustomerDetails(customerId)
        },
        // 切换修改
        changeModifyCustomer() {
            let that = this,
                { customerType } = that.customerDetails
            that.dialogType = 'modify'
            that.title = '修改客户信息';
            that.disabledInput = false;
            that.addCusomerformArr = (customerType == 'C' ? that.personalForm : that.enterpriseForm)
            that.editviewVisibleVisible = true;
        },
        // 新增企业客户接口
        addEnterpriseCustomer(form) {
            let that = this,
                { customerType, customerName, contact, customerPhone, contactPhone1, contactPhone2, customerCity, customerAddress } = this.addCusomerform,
                checkCity = customerCity ? that.$common.checkCityArr(customerCity) : '';
            that.$refs[form].validate((valid) => {
                if (valid) {
                    that.$confirm('确认新增?', '提示').then(() => {
                        that.$http.addEnterpriseCustomer({
                            lastModifyUserId: parseInt(that.$store.state.userId),
                            token: that.$getStorage('token'),
                            customerType,
                            customerName,
                            contact,
                            customerPhone,
                            contactPhone1,
                            contactPhone2,
                            customerCity: checkCity,
                            customerAddress
                        }).then(res => {
                            that.$common.message(res.msg, 'success')
                            setTimeout(() => {
                                that.addCustomerDialog = false
                                that.refresh()
                            }, 500);
                        })
                    }).catch(() => { })
                }
            })
        },
        // 企业客户列表详情接口
        enterpriseCustomerDetails(customerId) {
            let that = this;
            that.$http.enterpriseCustomerDetails({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                customerId
            }).then(res => {
                let { customerType, customerName, contact, customerPhone, contactPhone1, contactPhone2, customerCity, customerAddress } = res.data,
                    city = that.$common.splitCityArr(customerCity),
                    cityAddress = customerCity + ' ' + customerAddress;
                that.addCusomerform = {
                    customerType,
                    customerName,
                    contact,
                    customerPhone,
                    contactPhone1,
                    contactPhone2,
                    customerCity: city,
                    customerAddress
                }
                that.radioDisabled = true;
                that.customerDetails = res.data;
                that.dialogType = 'showCustomerDetail';
                that.title = '客户信息';
                that.disabledInput = true;
                that.addCusomerformArr = (customerType == 'C' ? that.personalFormDetail : that.enterpriseFormDetail)
                that.addCusomerform.customerCityAddress = cityAddress
                that.addCustomerDialog = true;
                that.viewVisibleVisible = true;
            })
        },
        // 修改企业客户接口
        reviseEnterpriseCustomer(form) {
            let that = this,
                { customerId, lastModifyDate } = that.customerDetails,
                { customerName, contact, customerPhone, contactPhone1, contactPhone2, customerCity, customerAddress } = this.addCusomerform,
                checkCity = that.$common.checkCityArr(customerCity);
            that.$refs[form].validate((valid) => {
                if (valid) {
                    that.$confirm('确定修改？', '提示').then(() => {
                        that.$http.reviseEnterpriseCustomer({
                            lastModifyUserId: parseInt(that.$store.state.userId),
                            token: that.$getStorage('token'),
                            customerId,
                            lastModifyDate,
                            customerName,
                            contact,
                            customerPhone,
                            contactPhone1,
                            contactPhone2,
                            customerCity: checkCity.split(' ').filter(i => i !== 'undefined').join(' '),
                            customerAddress
                        }).then(res => {
                            that.$common.message(res.msg, 'success')
                            setTimeout(() => {
                                that.addCustomerDialog = false
                                that.refresh()
                            }, 500);
                        })
                    }).catch(() => { })
                }
            })
        },
        // 删除业客户接口
        removeEnterpriseCustomer() {
            let that = this;
            that.$confirm('确定删除？', '提示').then(() => {
                that.$http.removeEnterpriseCustomer({
                    lastModifyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    customerId: that.customerId
                }).then(res => {
                    that.$common.message(res.msg, 'success')
                    setTimeout(() => {
                        that.addCustomerDialog = false
                        that.refresh()
                    }, 500);
                })
            }).catch(() => { })
        },
        // // 切换客户类型
        customerTypeChange(value) {
            if (value == 'C') {//个人
                this.addCusomerformArr = this.personalForm
            } else {
                this.addCusomerformArr = this.enterpriseForm
            }
            this.customerTypeVal = value
        },
        // 选中省市区
        handleChangeCity(value) {
            this.addCusomerform.customerCity = value
        },
        // 复制操作
        copy(context) {
            // 创建输入框元素
            let oInput = document.createElement('input');
            // 将想要复制的值
            oInput.value = context;
            // 页面底部追加输入框
            document.body.appendChild(oInput);
            // 选中输入框
            oInput.select();
            // 执行浏览器复制命令
            document.execCommand('Copy');
            // 弹出复制成功信息
            this.$message.success('复制成功');
            // 复制后移除输入框
            oInput.remove();
        }
    }
}

</script>
<style lang='less' scoped>
@import '../style/customerList.less';

.CustomerList {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .feature {
            margin-bottom: 20px;

            .search_input {
                input {
                    background-color: #FFF;
                    border-radius: 4px 0 0 4px;
                    border: 1px solid #E0E1E3;
                    box-sizing: border-box;
                    color: #606266;
                    display: inline-block;
                    height: 50px;
                    line-height: 50px;
                    outline: 0;
                    padding: 0 20px;
                    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                    width: 400px;
                    border-right: none;
                    font-size: 16px;
                }

                .search_icon {
                    width: 80px;
                    height: 50px;
                    border-top-right-radius: 4px !important;
                    border-top-left-radius: 0px !important;
                    border-bottom-right-radius: 4px !important;
                    border-bottom-left-radius: 0px !important;
                    color: #FFFFFF;
                    background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                }
            }

            .addBtn {
                font-size: 18px;
                width: 120px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #FFFFFF;
                border-radius: 10px;
                background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                cursor: pointer;

                i {
                    margin-right: 10px;
                }
            }
        }

        .customerTotal {
            margin-bottom: 20px;
        }

        .list {
            .item {
                width: 440px;
                height: 140px;
                background: #F8F8F8;
                border-radius: 10px;
                margin-bottom: 20px;
                padding: 20px;

                img {
                    width: 140px;
                    height: 140px;
                    border-radius: 10px;
                }

                .textBox {
                    width: 245px;

                    .name {
                        font-size: 20px;
                        font-weight: 400;
                        color: #000000;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 14px;
                    }

                    .type {
                        font-size: 16px;
                        font-weight: 400;
                        color: #999999;
                        margin-bottom: 24px;
                    }
                }

                .checkbtn {
                    width: 80px;
                    height: 40px;
                    border: 1px solid #1785F6;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 16px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #1785F6;
                    cursor: pointer;
                }
            }
        }

    }
    .textarea {
        .el-input__inner{
            font-size: 18px !important;
        }
    }

    .el-dialog {
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #333333;
        font-size: 30px;
        padding: 0 70px 0;
        font-weight: bold;
    }

    /deep/.el-dialog__header {
        padding-top: 60px !important;
    }

    /deep/ .el-dialog__body {
        padding: 40px 70px;
    }
}
</style>


